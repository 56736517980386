import { CAMPAIGN_DELETION_REMINDER_LS_KEY, NAME } from '@/app/campaigns/constants';
import { MODAL_OPTIONS } from '@/app/modals/constants';

import {
    PencilIcon,
    DocumentDuplicateIcon,
    ArrowUpTrayIcon,
    TrashIcon,
    SquaresPlusIcon,
    ChartBarSquareIcon,
    UsersIcon,
    WrenchScrewdriverIcon,
    ArchiveBoxIcon,
    FolderIcon,
} from '@heroicons/react/24/outline';
import Router from 'next/router';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo } from 'react';

import {
    getAnalyticsContactsUrl,
    getAnalyticsUrl,
    getCampaignIsArchived,
    getCampaignIsBuilding,
    getIntegrationsUrl,
    isCampaignDeletionReminderSet,
} from '@/app/campaigns/helpers';
import { deleteCampaign, getLoading } from '@/app/campaigns/models/delete';
import { restoreCampaign } from '@/app/campaigns/models/restore';
import { setRenaming } from '@/app/campaigns/models/update';
import { FunnelSetting } from '@/app/funnelSettings/funnelSettings/types';
import { showModal } from '@/app/modals/models/modals';
import { Modals } from '@/app/modals/types';
import { useWorkspaces } from '@/app/workspaces/hooks/useWorkspaces';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

import MoreMenuView from './MoreMenu.view';
import MoveToWorkspaceSubmenu from './MoveToWorkspaceSubmenu';

import type { MenuItem } from './Item';
import type { CampaignResource } from '../../../types';
import type { PopperContentProps } from '@radix-ui/react-popper';

export interface Props {
    campaign: CampaignResource;
    align?: PopperContentProps['align'];
    onOpenChange?: (open: boolean) => void;
}

export type MenuItems = MenuItem[][];

const MoreMenuController = ({ campaign, align, onOpenChange }: Props) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();
    const deleting = useAppSelector(getLoading);

    const { hasCustomWorkspaces, isAllowedToHaveCustomWorkspaces } = useWorkspaces();

    const isArchived = getCampaignIsArchived(campaign);
    const isBuilding = getCampaignIsBuilding(campaign);

    const handleRenameClick = useCallback(() => {
        dispatch(setRenaming(campaign.id));
    }, [campaign.id, dispatch]);

    const handleDuplicateClick = useCallback(() => {
        dispatch(showModal(Modals.DUPLICATE_CAMPAIGN, { campaign }));
    }, [campaign, dispatch]);

    const handleArchive = useCallback(async () => {
        if (isBuilding) {
            return;
        }

        dispatch(showModal(Modals.CONFIRM_ARCHIVE_CAMPAIGN, { campaignId: campaign.id }));
    }, [campaign.id, dispatch, isBuilding]);

    const handleAnalyticsClick = useCallback(async () => {
        await Router.push(getAnalyticsUrl(campaign.id));
    }, [campaign.id]);

    const handleContactsClick = useCallback(async () => {
        await Router.push(getAnalyticsContactsUrl(campaign.id));
    }, [campaign.id]);

    const handleIntegrationsClick = useCallback(async () => {
        await Router.push(getIntegrationsUrl(campaign.id));
    }, [campaign.id]);

    const handleRestoreClick = useCallback(() => {
        dispatch(restoreCampaign(campaign.id));
    }, [campaign.id, dispatch]);

    const onDeleteConfirm = useCallback(() => {
        dispatch(deleteCampaign(campaign.id));
    }, [campaign.id, dispatch]);

    const handleDeleteClick = useCallback(() => {
        if (isCampaignDeletionReminderSet()) {
            onDeleteConfirm();
        } else {
            dispatch(
                showModal(Modals.DELETION_ALERT, {
                    texts: {
                        title: t('delete-campaign-warning-title'),
                        description: t('delete-campaign-warning-description'),
                    },
                    onDelete: onDeleteConfirm,
                    loading: deleting,
                    reminderKey: CAMPAIGN_DELETION_REMINDER_LS_KEY,
                }),
            );
        }
    }, [deleting, dispatch, onDeleteConfirm, t]);

    const handleSettingsClick = useCallback(() => {
        dispatch(
            showModal(
                Modals.FUNNEL_SETTINGS,
                { campaignId: campaign.id, setting: FunnelSetting.Sharing },
                MODAL_OPTIONS[Modals.FUNNEL_SETTINGS],
            ),
        );
    }, [campaign.id, dispatch]);

    const menuItems: MenuItems = useMemo(
        () => [
            [
                {
                    text: 'contacts',
                    action: handleContactsClick,
                    icon: () => <UsersIcon className="size-4" />,
                },
                {
                    text: 'analytics',
                    action: handleAnalyticsClick,
                    icon: () => <ChartBarSquareIcon className="size-4" />,
                },
                {
                    text: 'integrations',
                    action: handleIntegrationsClick,
                    icon: () => <SquaresPlusIcon className="size-4" />,
                    mobileHidden: true,
                },
                {
                    text: 'form-settings',
                    action: handleSettingsClick,
                    icon: () => <WrenchScrewdriverIcon className="size-4" />,
                    mobileHidden: true,
                },
            ],
            [
                {
                    text: 'form-rename',
                    action: handleRenameClick,
                    icon: () => <PencilIcon className="size-4" />,
                },
                {
                    text: 'duplicate',
                    action: handleDuplicateClick,
                    icon: () => <DocumentDuplicateIcon className="size-4" />,
                },
                ...(isAllowedToHaveCustomWorkspaces && hasCustomWorkspaces
                    ? [
                          {
                              text: 'move-to',
                              icon: () => <FolderIcon className="size-4" />,
                              submenu: () => <MoveToWorkspaceSubmenu campaignId={campaign.id} />,
                          },
                      ]
                    : []),
            ],
            [
                {
                    text: 'form-archive',
                    action: handleArchive,
                    icon: () => <ArchiveBoxIcon className="size-4" />,
                    danger: true,
                },
            ],
        ],
        [
            campaign.id,
            hasCustomWorkspaces,
            isAllowedToHaveCustomWorkspaces,
            handleAnalyticsClick,
            handleArchive,
            handleContactsClick,
            handleDuplicateClick,
            handleIntegrationsClick,
            handleRenameClick,
            handleSettingsClick,
        ],
    );

    const archivedMenuItems: MenuItems = [
        [
            {
                text: 'form-restore',
                action: handleRestoreClick,
                icon: () => <ArrowUpTrayIcon className="size-4" />,
            },
            {
                text: 'form-delete',
                action: handleDeleteClick,
                danger: true,
                icon: () => <TrashIcon className="size-4" />,
            },
        ],
    ];

    return (
        <MoreMenuView
            align={align}
            menuItems={isArchived ? archivedMenuItems : menuItems}
            campaignId={campaign.id}
            onOpenChange={onOpenChange}
        />
    );
};

export default MoreMenuController;
