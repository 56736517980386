import { FILTER_QUERY_MAP } from '@/app/campaigns/constants';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { getPageFromUrl } from '@/app/campaigns/helpers';
import { getCampaignPagination, getFilter } from '@/app/campaigns/models/overview';
import { useAppSelector } from '@/core/redux/hooks';
import { usePagination } from '@/hooks/usePagination';
import { cn } from '@/utils/cn';
import { tw } from '@/utils/tw';

interface Props {
    className?: string;
}

const chevronClasses = tw`relative inline-flex size-8 items-center justify-center rounded-md text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800`;

export const Pagination = ({ className }: Props) => {
    const pagination = useAppSelector(getCampaignPagination);
    const filter = useAppSelector(getFilter);
    const router = useRouter();

    const query = FILTER_QUERY_MAP[filter];
    const totalCount = pagination?.totalCount ?? 0;
    const pageCount = pagination?.pageCount ?? 0;
    const currentPage = pagination?.self ? getPageFromUrl(pagination.self) : 1;
    const wrapperClasses = cn('flex items-center justify-center bg-white px-4', className);

    const paginationRange = usePagination({
        totalCount: totalCount,
        pageSize: query.limit,
        currentPage,
    });

    if (pageCount < 2 || !paginationRange) {
        return null;
    }

    let previousPage = currentPage - 1;
    let nextPage = currentPage + 1;

    if (previousPage < 1) {
        previousPage = pageCount;
    } else if (nextPage > pageCount) {
        nextPage = 1;
    }

    const getPageRedirectUrl = (page: number) => {
        const cleanUrl = router?.asPath.split('?')[0];

        return `${cleanUrl}?page=${page}`;
    };

    return (
        <div className={wrapperClasses}>
            <div className="flex flex-1 items-center justify-center">
                <nav
                    className="relative z-0 flex w-full items-center justify-center space-x-2 rounded-md"
                    aria-label="Pagination"
                >
                    <Link role="button" href={getPageRedirectUrl(previousPage)}>
                        <span aria-current="page" className={chevronClasses}>
                            <ChevronLeftIcon className="size-5" />
                        </span>
                    </Link>

                    {paginationRange.map((page, index) => {
                        const linkClasses = cn(
                            'relative inline-flex size-8 items-center justify-center rounded-md text-sm font-medium',
                            {
                                'bg-blue-50 text-blue-500': page === currentPage,
                                'bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-800':
                                    page !== currentPage,
                            },
                        );

                        if (page === '...') {
                            return (
                                <span
                                    className={cn('pointer-event-none', linkClasses)}
                                    key={`pagination-dots-${index}`}
                                >
                                    {page}
                                </span>
                            );
                        }

                        return (
                            <Link
                                role="button"
                                href={getPageRedirectUrl(page as number)}
                                key={`pagination-${page}`}
                            >
                                <span className={linkClasses}>{page}</span>
                            </Link>
                        );
                    })}

                    <Link role="button" href={getPageRedirectUrl(nextPage)}>
                        <div aria-current="page" className={chevronClasses}>
                            <ChevronRightIcon className="size-5" />
                        </div>
                    </Link>
                </nav>
            </div>
        </div>
    );
};
