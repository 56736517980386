import Link from 'next/link';

import { getAnalyticsContactsUrl } from '@/app/campaigns/helpers';
import { getCrmUrl } from '@/app/crm/helpers';

export interface Props {
    campaignId: string;
    number: number;
    text: string;
    isV3Funnel: boolean;
}

const KpiBadge = ({ campaignId, number, text, isV3Funnel }: Props) => {
    const contactsUrl = isV3Funnel ? getAnalyticsContactsUrl(campaignId) : getCrmUrl(campaignId);

    return (
        <Link
            href={contactsUrl}
            className="bump mr-2 flex h-8 flex-initial items-center rounded-md bg-blue-50 px-3"
        >
            <p className="text-xs text-blue-500">{`${number} ${text}`}</p>
        </Link>
    );
};

export default KpiBadge;
