import { setRenaming, updateCampaign } from '@/app/campaigns/models/update';
import { useAppDispatch } from '@/core/redux/hooks';

import Form from './Form';

import type { CampaignResource } from '@/app/campaigns/types';

export interface Props {
    campaign: CampaignResource;
    className?: string;
}

const RenameCampaignForm = ({ campaign, className }: Props) => {
    const dispatch = useAppDispatch();

    const handleSubmit = (values: CampaignResource) => {
        dispatch(updateCampaign(values));
        dispatch(setRenaming(''));
    };

    return <Form initialValues={campaign} className={className} onSubmit={handleSubmit} />;
};

export default RenameCampaignForm;
