import Link from 'next/link';

import { TotalContacts } from '@/app/campaigns/components/Overview/Table/FunnelTable/Row/ContactsCount/TotalContacts';
import { getCampaignContactCount, getCampaignIsArchived } from '@/app/campaigns/helpers';

import type { CampaignResource } from '@/app/campaigns/types';

interface Props {
    campaign: CampaignResource;
    link: string;
}

export const ContactCount = ({ campaign, link }: Props) => {
    const totalContacts = getCampaignContactCount(campaign);
    const isArchived = getCampaignIsArchived(campaign);

    if (isArchived) {
        return <TotalContacts count={totalContacts} />;
    }

    return (
        <Link href={link} className="h-8">
            <TotalContacts count={totalContacts} />
        </Link>
    );
};
