import { NAME } from '@/app/campaigns/constants';
import { STATUS_COLOR_MAP } from '@/app/crm/constants';

import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import WarningPopover from '@/app/campaigns/components/Overview/V3WarningIcon/WarningPopover';
import { getCampaignContactCount, getCampaignCRMStatus } from '@/app/campaigns/helpers';
import Popover from '@/ui/components/Popover';
import Tooltip from '@/ui/components/Tooltip';
import { cn } from '@/utils/cn';

import type { CampaignResource } from '@/app/campaigns/types';
import type { CampaignCRMStatus } from '@/app/crm/types';

export interface Props {
    campaign: CampaignResource;
    link: string;
}

export const CRMStatusView = ({ campaign, link }: Props) => {
    const { t } = useTranslation(NAME);
    const crmStatus = getCampaignCRMStatus(campaign);
    const totalContactsCount = getCampaignContactCount(campaign);
    const isFunnelOutdated = campaign?.attributes?.trackingVersion === 'v3';
    const isCRMEmpty = totalContactsCount === 0;

    if (isFunnelOutdated) {
        return (
            <Popover
                openOnHover
                animateFrom="none"
                placement="top"
                mainAxisOffsetValue={5}
                crossAxisOffsetValue={0}
                fallbackPlacements={['top-start']}
                render={() => <WarningPopover />}
            >
                <span className="cursor-default text-sm leading-7 text-gray-400">
                    {t('outdated-funnel')}
                </span>
            </Popover>
        );
    }

    if (isCRMEmpty) {
        return (
            <Link href={link} className="flex items-center gap-2">
                <Tooltip content={`0 ${t('contacts')}`}>
                    <span className="text-sm leading-7 text-gray-400">{t('no-contacts')}</span>
                </Tooltip>
            </Link>
        );
    }

    const crmStatusesWithContacts = crmStatus.filter(({ count }) => count > 0);
    const statusBarList = crmStatusesWithContacts.map(
        (status: CampaignCRMStatus, index: number) => {
            const percentage = Math.round((status.count / totalContactsCount) * 100);
            const colorClass = STATUS_COLOR_MAP[status.color]?.backgroundSolid;

            return (
                <Tooltip
                    key={`${status.name}-${status.color}`}
                    content={`${status.name}: ${status.count} ${t('contacts')}`}
                    offsetValue={0}
                >
                    <div className="h-6 rounded-[1px] py-2.5" style={{ width: `${percentage}%` }}>
                        <div
                            className={cn('h-1.5 w-full', colorClass, {
                                'rounded-l-full': index === 0,
                                'rounded-r-full': index === crmStatusesWithContacts.length - 1,
                            })}
                        />
                    </div>
                </Tooltip>
            );
        },
    );

    return (
        <Link href={link} className="flex items-center gap-2">
            <div className="align-center flex h-6 w-48 gap-0.5 overflow-hidden xl:w-56">
                {...statusBarList}
            </div>
        </Link>
    );
};
