import { NAME } from '@/app/campaigns/constants';
import { TRACKING_EVENTS } from '@/core/tracking/constants';

import { StarIcon } from '@heroicons/react/24/outline';
import { StarIcon as StarIconFilled } from '@heroicons/react/24/solid';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';

import { getCampaignIsFav } from '@/app/campaigns/helpers';
import { getLoading, updateCampaign } from '@/app/campaigns/models/update';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { track } from '@/core/tracking';
import { Tooltip } from '@/ui/components/Tooltip';

import type { CampaignResource } from '@/app/campaigns/types';
import type { Placement } from '@floating-ui/react';
import type { Variants } from 'framer-motion';
import type { MouseEvent } from 'react';

interface Props {
    campaign: CampaignResource;
    className?: string;
    iconClassName?: string;
    tooltipPlacement?: Placement;
    disabled?: boolean;
}

const pinned: Variants = {
    hidden: {
        opacity: 0,
        scale: 0.6,
        transition: { type: 'ease', duration: 0.15 },
    },
    visible: {
        opacity: 1,
        scale: 1,
        transition: { type: 'spring', mass: 1.5, damping: 30, stiffness: 1000 },
    },
};

const unpinned = {
    hidden: { opacity: 0, scale: 0.6, transition: { type: 'ease', duration: 0.15 } },
    visible: {
        opacity: 1,
        scale: 1,
        transition: { type: 'ease', duration: 0.15 },
    },
};

export const FavButton = ({
    campaign,
    className,
    tooltipPlacement = 'left',
    iconClassName = 'size-4',
    disabled,
}: Props) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();
    const isFav = getCampaignIsFav(campaign);
    const loading = useAppSelector(getLoading);

    const handleFavourite = (event: MouseEvent) => {
        // prevent click through to campaign editor
        event.stopPropagation();

        // Tracking
        track(TRACKING_EVENTS.campaign.overview.pin, {
            is_unpin: isFav,
        });

        // Update campaign
        let updatedCampaign: CampaignResource;

        if (isFav) {
            updatedCampaign = {
                ...campaign,
                attributes: {
                    ...campaign.attributes,
                    isFav: false,
                },
            };
        } else {
            updatedCampaign = {
                ...campaign,
                attributes: {
                    ...campaign.attributes,
                    isFav: true,
                },
            };
        }

        dispatch(updateCampaign(updatedCampaign));
    };

    if (disabled && !isFav) {
        return null;
    }

    return (
        <Tooltip
            content={isFav ? t('remove-favorite') : t('add-favorite')}
            placement={tooltipPlacement}
            disabled={disabled}
        >
            <button className={className} onClick={handleFavourite} disabled={loading || disabled}>
                <AnimatePresence mode="popLayout" initial={false}>
                    {isFav ? (
                        <motion.span
                            key="pinned"
                            variants={pinned}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                        >
                            <StarIconFilled className={iconClassName} />
                        </motion.span>
                    ) : (
                        <motion.span
                            key="unpinned"
                            variants={unpinned}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                        >
                            <StarIcon className={iconClassName} />
                        </motion.span>
                    )}
                </AnimatePresence>
            </button>
        </Tooltip>
    );
};
