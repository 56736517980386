import { NAME } from '@/app/campaigns/constants';

import { LinkIcon } from '@heroicons/react/20/solid';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import CopyToClipboard from 'react-copy-to-clipboard';
import { isMobile } from 'react-device-detect';

import KpiBadge from '@/app/campaigns/components/Overview/Grid/CampaignCard/Footer/KpiBadge';
import MoreMenu from '@/app/campaigns/components/Overview/MoreMenu';
import RenameCampaignForm from '@/app/campaigns/components/RenameCampaignForm';
import {
    getCampaignContactCount,
    getCampaignIsArchived,
    getCampaignIsLive,
    getCampaignStatus,
    getCampaignTrackingVersion,
    getCampaignUrl,
    getLinkUrl,
    getMinifiedCoverImage,
} from '@/app/campaigns/helpers';
import { getRenaming } from '@/app/campaigns/models/update';
import { getCrmUrl } from '@/app/crm/helpers';
import { showToast } from '@/app/toasts/utils/showToast';
import { useAppSelector } from '@/core/redux/hooks';
import { useDateFormat } from '@/hooks/useDateFormat';
import { cn } from '@/utils/cn';
import { CapabilitiesTarget, useUserCapabilities } from '@/utils/hooks/useUserCapabilities';

import StatusBadge from '../../Grid/CampaignCard/Footer/StatusBadge';

import type { CampaignResource, CampaignStatus } from '@/app/campaigns/types';

interface Props {
    campaign: CampaignResource;
}

const TableItem = ({ campaign }: Props) => {
    const { t } = useTranslation(NAME);

    const { canUpdate } = useUserCapabilities(CapabilitiesTarget.Campaign);
    const isV3Funnel = getCampaignTrackingVersion(campaign) === 'v3';

    const linkClass = cn('flex-1', {
        ['pointer-events-none cursor-not-allowed']: campaign.attributes.isArchived,
    });
    const renamingCampaignId = useAppSelector(getRenaming);
    const isActiveRenaming = renamingCampaignId === campaign.id;
    const contactCount = getCampaignContactCount(campaign);
    const date = useDateFormat(new Date(campaign.attributes.updatedAt));
    const link = canUpdate ? getLinkUrl(isMobile, campaign.id) : getCrmUrl(campaign.id);

    const showCopyToast = () => {
        showToast({ message: `${NAME}:link-copied`, type: 'info' });
    };

    return (
        <div className="grid grid-cols-3 gap-2">
            <Link className={linkClass} href={link}>
                <div
                    className="h-24 rounded-md bg-cover bg-center shadow sm:h-32"
                    style={{
                        backgroundImage: `url(${getMinifiedCoverImage(campaign)})`,
                    }}
                />
            </Link>
            <div className="relative col-span-2">
                <div className="relative flex items-center">
                    {isActiveRenaming ? (
                        <div className="rounded-lg bg-gray-100 px-2 py-1">
                            <RenameCampaignForm campaign={campaign} className="text-base" />
                        </div>
                    ) : (
                        <Link className={linkClass} href={link}>
                            <div className="flex-1 pl-2">
                                <p className="overflow-hidden overflow-ellipsis text-base font-medium sm:text-lg md:text-xl">
                                    {campaign.attributes.name}
                                </p>
                            </div>
                        </Link>
                    )}
                    {canUpdate && <MoreMenu campaign={campaign} align="start" />}
                </div>
                <p className="pl-2 text-sm text-gray-400 md:text-base">{date}</p>
                <div className="flex pl-2 pt-3">
                    {getCampaignIsLive(campaign) && !getCampaignIsArchived(campaign) && (
                        <CopyToClipboard text={getCampaignUrl(campaign)} onCopy={showCopyToast}>
                            <div className="mr-2 flex cursor-pointer items-center rounded-md bg-blue-50 fill-current px-2 text-blue-500">
                                <LinkIcon className="size-4" />
                            </div>
                        </CopyToClipboard>
                    )}
                    <StatusBadge
                        status={getCampaignStatus(campaign) as CampaignStatus}
                        archived={getCampaignIsArchived(campaign)}
                    />
                    {getCampaignIsLive(campaign) && !getCampaignIsArchived(campaign) && (
                        <div className="hidden sm:block">
                            <KpiBadge
                                campaignId={campaign.id}
                                number={contactCount}
                                text={contactCount > 1 ? t('contacts') : t('contact')}
                                isV3Funnel={isV3Funnel}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TableItem;
