import { useState } from 'react';
import { isMobile } from 'react-device-detect';

import { CopyLinkButton } from '@/app/campaigns/components/Overview/CopyLinkButton';
import { FavButton } from '@/app/campaigns/components/Overview/FavButton';
import { ConversionRate } from '@/app/campaigns/components/Overview/Table/FunnelTable/Row/ConversionRate';
import {
    getAnalyticsContactsUrl,
    getAnalyticsUrl,
    getCampaignIsArchived,
    getCampaignIsLive,
    getCampaignTrackingVersion,
    getCampaignUrl,
    getHasHeaderColumn,
    getLinkUrl,
} from '@/app/campaigns/helpers';
import { usePreloadEditor } from '@/app/campaigns/hooks/usePreloadEditor';
import { CampaignColumnName } from '@/app/campaigns/types';
import { getCrmUrl } from '@/app/crm/helpers';
import { cn } from '@/utils/cn';
import { CapabilitiesTarget, useUserCapabilities } from '@/utils/hooks/useUserCapabilities';
import { CRMStatusView } from 'modules/app/campaigns/components/Overview/Table/FunnelTable/Row/CRMStatusView';

import { ContactCount } from './ContactsCount';
import CoverImage from './CoverImage';
import Status from './Status';
import Title from './Title';
import MoreMenu from '../../../MoreMenu';

import type { CampaignResource, CampaignTableColumnItem } from '@/app/campaigns/types';

interface Props {
    campaign: CampaignResource;
    columns: CampaignTableColumnItem[];
}

export const Row = ({ campaign, columns }: Props) => {
    const preload = usePreloadEditor(campaign.id);
    const isArchived = getCampaignIsArchived(campaign);
    const campaignUrl = getCampaignUrl(campaign);
    const isV3Funnel = getCampaignTrackingVersion(campaign) === 'v3';
    const [hasActiveMenu, setHasActiveMenu] = useState(false);

    const { canUpdate } = useUserCapabilities(CapabilitiesTarget.Campaign);
    const linkToContacts = isV3Funnel
        ? getAnalyticsContactsUrl(campaign.id)
        : getCrmUrl(campaign.id);
    const link = canUpdate ? getLinkUrl(isMobile, campaign.id) : linkToContacts;
    const linkToAnalytics = canUpdate ? getAnalyticsUrl(campaign.id) : linkToContacts;

    const getWrapperClassName = (columnName: CampaignColumnName) =>
        cn('whitespace-nowrap py-4 pl-3 pr-1.5 xl:pl-5 xl:pr-3', {
            hidden: !getHasHeaderColumn(columns, columnName),
        });

    return (
        <tr
            className={cn('relative hover:bg-gray-50', { 'bg-gray-50': hasActiveMenu })}
            onMouseEnter={isArchived || !canUpdate ? undefined : preload}
        >
            <td className={getWrapperClassName(CampaignColumnName.Name)}>
                <div className="flex shrink items-center gap-2.5">
                    <CoverImage campaign={campaign} link={link} />
                    <Title campaign={campaign} link={link} />
                </div>
            </td>
            <td className={getWrapperClassName(CampaignColumnName.IsFav)}>
                <div className="relative">
                    <FavButton
                        campaign={campaign}
                        className={cn(
                            'relative flex size-8 shrink items-center justify-center text-amber-500',
                            {
                                'cursor-not-allowed opacity-50': isArchived,
                            },
                        )}
                        iconClassName="size-5"
                        tooltipPlacement="right"
                        disabled={!canUpdate || isArchived}
                    />
                </div>
            </td>
            <td className={getWrapperClassName(CampaignColumnName.Status)}>
                <div className="flex shrink items-center gap-2">
                    <Status campaign={campaign} />
                    {getCampaignIsLive(campaign) && !getCampaignIsArchived(campaign) && (
                        <CopyLinkButton link={campaignUrl} tooltipPlacement="right" />
                    )}
                </div>
            </td>
            <td className={getWrapperClassName(CampaignColumnName.Conversion)}>
                <div className="flex shrink items-center">
                    <ConversionRate campaign={campaign} link={linkToAnalytics} />
                </div>
            </td>

            <td className={getWrapperClassName(CampaignColumnName.Contacts)}>
                <div className="flex shrink items-center">
                    <ContactCount campaign={campaign} link={linkToContacts} />
                </div>
            </td>
            <td className={getWrapperClassName(CampaignColumnName.CRM)}>
                <div className="flex shrink items-center">
                    <CRMStatusView campaign={campaign} link={linkToContacts} />
                </div>
            </td>
            <td className={getWrapperClassName(CampaignColumnName.Actions)}>
                {canUpdate && (
                    <MoreMenu campaign={campaign} align="start" onOpenChange={setHasActiveMenu} />
                )}
            </td>
        </tr>
    );
};
