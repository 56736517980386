import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { MobileOnlyView } from 'react-device-detect';

import { removePageFromQuery } from '@/app/campaigns/helpers';
import { searchCampaigns, setSearching } from '@/app/campaigns/models/campaigns';
import { useWorkspaces } from '@/app/workspaces/hooks/useWorkspaces';
import { useNavigationUpdatesEnabled } from '@/core/loli-feature-flags/features/navigationUpdates';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { cn } from '@/utils/cn';
import { EMPTY_STRING } from '@/utils/empty';
import { useUserCapabilities, CapabilitiesTarget } from '@/utils/hooks/useUserCapabilities';

import DesktopActionButtons from './DesktopActionButtons';
import { Filter } from './Filter';
import { GlobalSearchToggle } from './GlobalSearchToggle';
import MobileSearch from './MobileSearch';
import { getSearch, setFilter, setIsGlobalSearch, setSearch } from '../../../models/overview';
import { CampaignFilter } from '../../../types';

const TopBar = () => {
    const dispatch = useAppDispatch();
    const router = useRouter();

    const { canUpdate } = useUserCapabilities(CapabilitiesTarget.Campaign);

    const search = useAppSelector(getSearch);

    const { activeWorkspace, defaultWorkspace, hasCustomWorkspaces } = useWorkspaces();

    const isDefaultWorkspace = activeWorkspace?.id === defaultWorkspace?.id;
    const workspaceName = activeWorkspace?.attributes?.name;

    const navbarUpdatesFeature = useNavigationUpdatesEnabled();

    // Reset search on unmount
    useEffect(() => {
        return () => {
            dispatch(setFilter(CampaignFilter.all));
            dispatch(setSearch(EMPTY_STRING));
        };
    }, [dispatch]);

    // Reset search on WS change
    useEffect(() => {
        dispatch(setFilter(CampaignFilter.all));
        dispatch(setSearch(EMPTY_STRING));
        dispatch(setIsGlobalSearch(false));
    }, [dispatch, activeWorkspace]);

    const triggerSearch = useMemo(() => {
        return debounce(() => dispatch(searchCampaigns({ crmCampaignsOnly: !canUpdate })), 500);
    }, [canUpdate, dispatch]);

    const handleSearch = async (value: string) => {
        // Remove current pagination params
        const { page } = router.query;

        if (page) {
            removePageFromQuery();
        }

        if (!search) {
            // Reset isGlobalSearch
            dispatch(setIsGlobalSearch(false));
        } else {
            dispatch(setSearching(true));
        }

        await dispatch(setSearch(value));
        triggerSearch();
    };

    const handleMobileSearchChange = async (values: { name: string }) => {
        handleSearch(values.name);
    };

    return (
        <div
            className={cn(
                'relative mt-14 flex flex-col items-start gap-4 transition-all duration-300 ease-in-out',
                {
                    'mb-18': hasCustomWorkspaces && !!search,
                    'mb-12': !hasCustomWorkspaces || !search,
                },
            )}
        >
            <div className="z-10 flex w-full items-center justify-between gap-1">
                {!canUpdate ? (
                    <h1 className="w-full text-xl font-semibold sm:text-3xl">{workspaceName}</h1>
                ) : (
                    <Filter
                        filterAllLabel={
                            navbarUpdatesFeature || isDefaultWorkspace ? null : workspaceName
                        }
                        options={
                            isDefaultWorkspace
                                ? null
                                : [CampaignFilter.all, CampaignFilter.archived]
                        }
                    />
                )}

                <DesktopActionButtons handleSearch={handleSearch} />
            </div>

            <MobileOnlyView className="relative z-10 flex w-full items-center justify-center">
                <MobileSearch onChange={handleMobileSearchChange} />
            </MobileOnlyView>

            {hasCustomWorkspaces && (
                <GlobalSearchToggle show={!!search} triggerSearch={triggerSearch} />
            )}
        </div>
    );
};

export default TopBar;
