import { useRouter } from 'next/router';
import { memo } from 'react';
import { useEffect } from 'react';

import EmptyState from '@/app/campaigns/components/Overview/EmptyState';
import { Pagination } from '@/app/campaigns/components/Overview/Pagination';
import { getFilteredCampaignList } from '@/app/campaigns/helpers';
import { getFilter, getOrder, reset } from '@/app/campaigns/models/overview';
import { TestId } from '@/app/campaigns/types';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import Loader from '@/ui/components/Loader';
import { CapabilitiesTarget, useUserCapabilities } from '@/utils/hooks/useUserCapabilities';

import Grid from './Grid';
import Table from './Table';
import { useLastDisplayedMode } from '../../hooks/useLastDisplayedMode';
import { fetchCampaigns, getCampaignsAsArray, getFetchingList } from '../../models/campaigns';

export interface Props {
    workspaceId: string;
}

const Overview = ({ workspaceId }: Props) => {
    const { query } = useRouter();
    const dispatch = useAppDispatch();

    const order = useAppSelector(getOrder);
    const filter = useAppSelector(getFilter);
    const campaigns = useAppSelector(getCampaignsAsArray);
    const loading = useAppSelector(getFetchingList);

    const isGrid = useLastDisplayedMode() === 'grid';
    const campaignCapability = useUserCapabilities(CapabilitiesTarget.Campaign);
    const campaignList = getFilteredCampaignList(campaigns, filter);

    useEffect(() => {
        dispatch(
            fetchCampaigns({
                crmCampaignsOnly: !campaignCapability.canUpdate,
                workspaceIds: workspaceId,
            }),
        );
        // Add `filter`, `order` and `page` deps to update campaign list on filter/order/page selection
    }, [filter, query.page, order, dispatch, workspaceId, campaignCapability.canUpdate]);

    // Effect to reset filter when `Overview` is unmounted
    useEffect(() => {
        return () => {
            dispatch(reset());
        };
    }, [dispatch]);

    const Display = isGrid ? Grid : Table;

    return (
        <Loader loading={loading} data-testid={TestId.Campaigns}>
            {!loading ? (
                <>
                    {campaigns.length > 0 ? (
                        <Display campaigns={campaignList} />
                    ) : (
                        <EmptyState workspaceId={workspaceId} />
                    )}
                    <Pagination />
                </>
            ) : null}
        </Loader>
    );
};

export default memo(Overview);
