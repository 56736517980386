import { NAME } from '@/app/campaigns/constants';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useTranslation } from 'react-i18next';

import { getCampaignById } from '@/app/campaigns/models/campaigns';
import { showToast } from '@/app/toasts/utils/showToast';
import { useWorkspaces } from '@/app/workspaces/hooks/useWorkspaces';
import { moveCampaign } from '@/app/workspaces/models/funnelSelection';
import { useDefaultWorkspaceEnabled } from '@/core/loli-feature-flags/features/defaultWorkspace';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

interface Props {
    campaignId?: string;
}

const MoveToWorkspaceSubmenu = ({ campaignId }: Props) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();
    const { sortedWorkspaces, workspaceLimit } = useWorkspaces();
    const campaign = useAppSelector((state) => getCampaignById(state, campaignId));
    const campaignWorkspaceId = campaign.relationships.workspace?.data?.id;

    const handleClick = (workspaceId: string) => async () => {
        if (campaignId) {
            const success = await dispatch(
                moveCampaign({ campaignId, workspaceId, shouldUpdateWorkspaceCampaigns: true }),
            );

            // Show success toast
            if (success) {
                showToast({
                    message: t('move-campaign-success-message'),
                    type: 'success',
                });
            }
        }
    };

    const workspaceIdsWithLimit = sortedWorkspaces
        ?.slice(0, workspaceLimit)
        ?.filter((workspace) => workspace.id !== campaignWorkspaceId);
    const isDefaultWorkspaceFFEnabled = useDefaultWorkspaceEnabled();

    return (
        <div className="flex w-52 flex-col rounded-lg border border-gray-200 bg-white p-2 text-sm">
            {workspaceIdsWithLimit?.map((workspace) => {
                const isDefaultWorkspace = !!workspace?.attributes?.default;

                return (
                    <DropdownMenu.Item asChild key={workspace?.id}>
                        <button
                            type="button"
                            onClick={handleClick(workspace?.id)}
                            className="w-full truncate rounded p-2 text-left text-gray-500 outline-none hover:bg-gray-100 hover:text-gray-800"
                        >
                            {isDefaultWorkspace && !isDefaultWorkspaceFFEnabled
                                ? 'Perspective Default'
                                : workspace?.attributes?.name}
                        </button>
                    </DropdownMenu.Item>
                );
            })}
        </div>
    );
};

export default MoveToWorkspaceSubmenu;
