import { NAME } from '@/app/campaigns/constants';

import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { removePageFromQuery } from '@/app/campaigns/helpers';
import { setSearching } from '@/app/campaigns/models/campaigns';
import { getIsGlobalSearch, setIsGlobalSearch } from '@/app/campaigns/models/overview';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { cn } from '@/utils/cn';

export interface Props {
    show: boolean;
    triggerSearch: () => void;
}

export const GlobalSearchToggle = ({ show, triggerSearch }: Props) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();
    const router = useRouter();

    const isGlobalSearch = useAppSelector(getIsGlobalSearch);

    const handleToggle = (value: boolean) => async () => {
        const { page } = router.query;

        if (page) {
            removePageFromQuery();
        }

        if (value !== isGlobalSearch) {
            await dispatch(setIsGlobalSearch(value));
            dispatch(setSearching(true));

            triggerSearch();
        }
    };

    const getButtonClassName = (active: boolean) =>
        cn('rounded-lg px-4 py-2 text-sm transition-all', {
            'bg-gray-100 text-gray-800': active,
            'text-gray-500 hover:bg-gray-100 hover:text-gray-800': !active,
        });

    return (
        <div
            className={cn(
                'absolute bottom-0 left-0 flex translate-y-9 gap-2 opacity-0 transition-all duration-200 ease-out',
                {
                    'translate-y-11 opacity-100': show,
                },
            )}
        >
            <button className={getButtonClassName(!isGlobalSearch)} onClick={handleToggle(false)}>
                {t('search-this-workspace')}
            </button>

            <button className={getButtonClassName(isGlobalSearch)} onClick={handleToggle(true)}>
                {t('search-all-workspaces')}
            </button>
        </div>
    );
};
