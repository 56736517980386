import { NAME } from '@/app/campaigns/constants';

import { ChevronRightIcon } from '@heroicons/react/20/solid';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useTranslation } from 'next-i18next';

import { cn } from '@/utils/cn';

import type { ReactElement } from 'react';

export interface MenuItem {
    text: string;
    action?: any;
    danger?: boolean;
    icon: () => JSX.Element;
    mobileHidden?: boolean;
    submenu?: () => ReactElement;
    hasPortal?: boolean;
}

const Item = ({ icon, text, danger, action, submenu, hasPortal = true }: MenuItem) => {
    const { t } = useTranslation(NAME);
    const Icon = icon;

    const ItemComponent = (
        <button
            onClick={() => {
                if (typeof action === 'function') {
                    action();
                }
            }}
            className={cn(
                'relative my-0.5 flex w-full cursor-pointer items-center justify-between rounded px-3 py-2 text-sm outline-none',
                danger
                    ? 'text-red-500 hover:bg-red-50'
                    : 'text-gray-500 hover:bg-gray-100 hover:text-gray-800',
            )}
        >
            <div className="flex items-center">
                <Icon />
                <span className="ml-4">{t(text)}</span>
            </div>
            {!!submenu && <ChevronRightIcon className="size-5" />}
        </button>
    );

    if (submenu) {
        const subContent = (
            <DropdownMenu.SubContent sideOffset={6} className="z-20">
                <div>{submenu()}</div>
            </DropdownMenu.SubContent>
        );

        return (
            <DropdownMenu.Sub>
                <DropdownMenu.SubTrigger asChild>{ItemComponent}</DropdownMenu.SubTrigger>
                {hasPortal ? <DropdownMenu.Portal>{subContent}</DropdownMenu.Portal> : subContent}
            </DropdownMenu.Sub>
        );
    }

    return <DropdownMenu.Item asChild>{ItemComponent}</DropdownMenu.Item>;
};

export default Item;
