import { NAME } from '@/app/campaigns/constants';

import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { cn } from '@/utils/cn';

interface Props {
    isArchived: boolean;
    src: string;
    alt: string;
    onRestore: () => void;
}

export const ImageContent = ({ isArchived, src, onRestore, alt }: Props) => {
    const { t } = useTranslation(NAME);
    const [loaded, setLoaded] = useState(false);

    const handleLoadComplete = () => {
        setLoaded(true);
    };

    return (
        <div className="relative isolate w-full p-2">
            <div className="relative rounded-lg bg-gray-50 aspect-[340/160]">
                <Image
                    src={src}
                    alt={alt}
                    fill
                    className={cn(
                        'rounded-lg object-cover opacity-0 transition-opacity duration-300 ease-out',
                        {
                            'opacity-100': loaded,
                        },
                    )}
                    onLoad={handleLoadComplete}
                />
            </div>

            {isArchived && (
                <div className="absolute left-2 top-2 z-10 flex w-[calc(100%-16px)] items-center justify-center rounded-lg bg-gray-900/20 opacity-0 backdrop-blur-sm transition-opacity aspect-[340/160] group-hover:opacity-100">
                    <button
                        className="flex h-10 items-center rounded-lg border bg-white px-4 text-sm text-gray-800 hover:bg-gray-50"
                        onClick={onRestore}
                    >
                        <ArrowUpTrayIcon className="mr-2 size-4 stroke-2" />
                        {t('form-restore')}
                    </button>
                </div>
            )}
        </div>
    );
};
