import { FEATURE_IDS } from '@/app/billing/constants';

import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { getOrder, setOrder } from '@/app/campaigns/models/overview';
import { CampaignColumnName, CampaignOrder } from '@/app/campaigns/types';
import { useFeatureAvailability } from '@/app/company/hooks/useFeatureAvailability';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

import FunnelTable from './FunnelTable';
import TableItem from './TableItem';
import { NAME } from '../../../constants';

import type { CampaignResource, CampaignTableColumnItem } from '@/app/campaigns/types';

interface Props {
    campaigns: CampaignResource[];
}

export const Table = ({ campaigns }: Props) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();
    const currentOrder = useAppSelector(getOrder);

    const isCrmFeatureAvailable = useFeatureAvailability(FEATURE_IDS.crm, {
        fallbackId: FEATURE_IDS.internal,
    });

    const handleSort = (columnId: CampaignOrder) => {
        let newOrder: CampaignOrder = CampaignOrder[columnId];

        const isCurrentOrder = CampaignOrder[columnId] === currentOrder;
        const isReversedOrder = currentOrder.startsWith('-');

        if (isCurrentOrder) {
            newOrder = CampaignOrder[`${columnId}Reverse`];
        } else if (isReversedOrder) {
            newOrder = CampaignOrder[columnId];
        }

        dispatch(setOrder(newOrder));
    };

    const columns: CampaignTableColumnItem[] = useMemo(() => {
        return [
            {
                name: CampaignColumnName.Name,
                text: t('name'),
                sortable: true,
            },
            {
                name: CampaignColumnName.IsFav,
                text: t('favorite'),
                sortable: true,
            },
            {
                name: CampaignColumnName.Status,
                text: t('status'),
                sortable: true,
            },
            {
                name: CampaignColumnName.Conversion,
                text: t('cvr'),
                sortable: true,
            },
            {
                name: CampaignColumnName.Contacts,
                text: t('contacts'),
                sortable: true,
            },
            isCrmFeatureAvailable && {
                name: CampaignColumnName.CRM,
                text: t('contacts-status'),
                sortable: false,
            },
            {
                name: CampaignColumnName.Actions,
                text: '',
                sortable: false,
            },
        ].filter(Boolean) as CampaignTableColumnItem[];
    }, [isCrmFeatureAvailable, t]);

    return (
        <>
            <div className="hidden pb-4 lg:block">
                <FunnelTable campaigns={campaigns} columns={columns} onSort={handleSort} />
            </div>

            {/* Mobile */}
            <div className="mb-4 flex flex-col gap-4 lg:hidden">
                {campaigns.map((campaign) => (
                    <TableItem campaign={campaign} key={campaign.id} />
                ))}
            </div>
        </>
    );
};

export default Table;
